<template>
    <div>
        <van-search
            v-model="name"
            placeholder="请输入搜索关键词"
            @search="getData"
            background="#4988FF"
            maxlength="10"
        >
            <template #left-icon>
                <van-icon
                    name="search"
                    size="22"
                />
            </template>
        </van-search>

        <div class="wrap">
            <template v-if="list.length">
                <van-collapse
                    v-for="(it, idx) in list"
                    :key="idx"
                    v-model="active"
                >
                    <van-collapse-item
                        :border="false"
                        :name="it.processTypeId"
                    >
                        <!-- :title="it.processTypeName" -->
                        <template #title>
                            <div style="display: flex; align-items: center; font-size: 16px">
                                <van-icon
                                    :name="require('@/assets/img/send-icon.svg')"
                                    size="22"
                                />
                                &nbsp;
                                {{ it.processTypeName }}
                            </div>
                        </template>

                        <van-cell
                            :key="index"
                            v-for="(item, index) in it.processList"
                            :title="item.name"
                            :url="'/#/sendInfo?id=' + item.id + isHasNav"
                        ></van-cell>
                    </van-collapse-item>
                </van-collapse>
            </template>

            <div
                class="empty"
                v-if="list.length == 0 && finished"
            >
                <van-empty
                    description="没有内容，空空如也~"
                    :image="require('@/assets/img/mag_pic_noinformation.svg')"
                ></van-empty>
            </div>

            <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list :finished="finished" @load="getData">
          <template v-if="list.length > 0">
            <div class="formBox">
              <van-cell
                border
                center
                :key="index"
                v-for="(item, index) in list"
                :title="item.name"
                is-link
                :url="'/#/sendInfo?id=' + item.id + isHasNav"
                :clickable="true"
              ></van-cell>
            </div>
          </template>
          <div class="empty" v-if="list.length == 0">
            <van-empty
              description="没有内容，空空如也~"
              :image="require('@/assets/img/mag_pic_noinformation.svg')"
            ></van-empty>
          </div>
        </van-list>
      </van-pull-refresh> -->
        </div>
    </div>
</template>

<script>
    import { getSendFlowList } from "@/api/flow";

    export default {
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                list: [],
                name: "",

                pageFrom: this.$route.query.from,

                active: [],
            };
        },
        components: {},
        watch: {},
        mounted() {
            if (document.querySelector(".cxd-PopUp")) {
                window.location.reload();
            }
            this.getData();
        },
        created() {},
        methods: {
            getData() {
                try {
                    let params = {
                        suspensionState: 1,
                        name: this.name,
                    };
                    if (this.pageFrom == "afterSales") {
                        params.processTypeId = 7;
                    }
                    this.active = [];
                    getSendFlowList(params).then((res) => {
                        console.log("res -> :", res);
                        if (res.status == 200) {
                            if (this.refreshing) {
                                this.list = [];
                                this.refreshing = false;
                            }

                            this.list = res.data || [];
                            console.log("this.list -> :", this.list);
                            if (this.name) {
                                this.active = this.list.map((item, index) => item.processTypeId);
                            }
                            this.loading = false;
                            this.finished = true;
                        }
                    });
                    console.log(11111111111, this.pageFrom);
                } catch (e) {
                    this.loading = false;
                    this.finished = true;
                    //TODO handle the exception
                }
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.loading = true;
                this.page = 1;
                // 重新加载数据
                this.getData();
            },
        },
        computed: {
            // 从哪个页面跳转过来的
            isHasNav() {
                return this.$route.query.from == "jntPc" ? "&hasNav=1" : "";
            },
        },
    };
</script>

<style lang="less" scoped>
    /deep/ .van-field__control {
        line-height: 1.75 !important;
    }
    .wrap {
        padding: 0 10px;

        /deep/ .van-collapse {
            margin: 10px 0;
        }

        /deep/ .van-collapse-item__content {
            border-radius: 8px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            padding: 0px 0 0 20px;
        }

        /deep/ .van-cell--clickable {
            border-radius: 8px;
        }

        /deep/ .van-collapse-item__title--expanded {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        // /deep/ .van-cell {
        //   margin: 12px 0;
        // }
    }
</style>
